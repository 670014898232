import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=4fad6131&scoped=true"
import script from "./Selection.vue?vue&type=script&lang=js"
export * from "./Selection.vue?vue&type=script&lang=js"
import style0 from "./Selection.vue?vue&type=style&index=0&id=4fad6131&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fad6131",
  null
  
)

export default component.exports